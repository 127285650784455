import React, { useEffect } from "react";
import Layout from "../../components/_App/layout";
import SEO from "../../components/_App/seo";
import Navbar from "../../components/_App/NavbarStyleFour";
import Footer from "../../components/_App/Footer";
import PageBanner from "../../components/Common/PageBanner";
import * as Icon from "react-feather";
import { Link } from "gatsby";
import BlogSidebar from "../../components/Blog/BlogSidebar";
import virtual_lesson from "../../assets/images/blog-image/virtual_lesson.jpg";
import vr from "../../assets/images/blog-image/vr.jpg";
import { CodeBlock, vs2015 } from "react-code-blocks";
import Blog from "../../assets/json/blog.json";

const articleData = {
    id: 13,
    title: "Testing nell'Ecosistema React",
    desc: "Metodologie e strumenti avanzati.",
    img: "/blog-image/ecosistema.jpg",
    page: "blog/ecosistema_react",
    data: "8 Set 2023",
    author: "Staff",
};

const BlogDetails = () => {
    const [isMobile, setMobile] = React.useState(false);

    const handleResize = () => {
        if (window.innerWidth < 1275) {
            setMobile(true);
        } else {
            setMobile(false);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);
    return (
        <Layout>
            <SEO
                title={articleData.title}
                meta={[
                    {
                        name: `description`,
                        content:
                            "In ambito software development, i test sono una componente fondamentale per garantire la qualità, l'affidabilità e la manutenibilità del codice.",
                    },
                    {
                        property: `og:title`,
                        content: articleData.title + "Appius, software house",
                    },
                ]}
            />

            <Navbar />

            <PageBanner pageTitle={articleData.title} />

            <div className="blog-details-area ptb-80">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="blog-details-desc">
                                <div className="article-image">
                                    <img
                                        src={
                                            require("../../assets/images" +
                                                articleData.img).default
                                        }
                                        alt="image"
                                    />
                                </div>

                                <div className="article-content">
                                    <div className="entry-meta">
                                        <ul>
                                            <li>
                                                <Icon.Clock />{" "}
                                                {articleData.data}
                                            </li>
                                            <li>
                                                <Icon.User />{" "}
                                                <Link to="#">
                                                    {articleData.author}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <p>
                                        In ambito software development, i test
                                        sono una componente fondamentale per
                                        garantire la qualità, l'affidabilità e
                                        la manutenibilità del codice. In React,
                                        che include sia la libreria React per lo
                                        sviluppo web che React Native per lo
                                        sviluppo di app mobili, i test rivestono
                                        un ruolo cruciale. Nel testo,
                                        esploreremo diverse metodologie e
                                        strumenti avanzati per testare
                                        componenti React e applicazioni React
                                        Native, tra cui Jest, React Testing
                                        Library e strumenti di simulazione per
                                        dispositivi mobili.
                                    </p>
                                    <h3>Metodologie di testing</h3>

                                    <h5>1. Test Unitari</h5>
                                    <p>
                                        In React, i test unitari sono la base
                                        del processo di testing. Si concentrano
                                        su parti specifiche del codice, come
                                        componenti o funzioni, isolandole dal
                                        resto dell'applicazione. Jest è uno dei
                                        framework di test più utilizzati per
                                        eseguire test unitari. Supporta l'uso di
                                        test snapshot in cui il risultato di un
                                        componente o di una parte del codice
                                        viene confrontato con uno snapshot
                                        precedentemente registrato, consentendo
                                        di individuare facilmente le modifiche
                                        non intenzionali.
                                    </p>
                                    <h5>2. Test di Integrazione</h5>
                                    <p>
                                        I test di integrazione verificano che
                                        diverse parti dell'applicazione
                                        interagiscano correttamente tra loro.
                                        Con Jest, è possibile creare suite di
                                        test che coprano scenari complessi, come
                                        l'interazione tra più componenti o la
                                        gestione dello stato globale. Inoltre,
                                        React Testing Library è uno strumento
                                        prezioso per eseguire test di
                                        integrazione, poiché si basa sulla
                                        simulazione degli eventi e
                                        dell'interazione utente, riproducendo
                                        meglio il comportamento
                                        dell'applicazione nel contesto reale.
                                    </p>
                                    <h5>3. Test End-to-End (E2E)</h5>
                                    <p>
                                        I test end-to-end simulano il flusso
                                        dell'utente attraverso l'intera
                                        applicazione, verificando che tutte le
                                        parti funzionino insieme in modo
                                        coerente. Strumenti come Cypress e Detox
                                        consentono di eseguire test E2E per
                                        applicazioni React Native. Questi
                                        strumenti offrono la possibilità di
                                        interagire con l'applicazione in modo
                                        simile a come lo farebbe un utente
                                        reale, consentendo la verifica di
                                        scenari complessi e realistici.
                                    </p>
                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                    <h3>Strumenti di testing</h3>
                                    <h5>1. Jest</h5>
                                    <p>
                                        Jest è un framework di test all-in-one
                                        ampiamente utilizzato in ambito React in
                                        quanto offre strumenti per eseguire test
                                        unitari, test di integrazione e test
                                        snapshot. Jest supporta il concetto di
                                        "mocking" che permette di simulare parti
                                        dipendenti per concentrarsi solo sulla
                                        logica da testare. Inoltre, la sua
                                        natura asincrona si allinea bene con
                                        l'approccio di React agli aggiornamenti
                                        asincroni dell'interfaccia utente.
                                    </p>
                                    <h5>2. React Testing Library</h5>
                                    <p>
                                        React Testing Library è un set di
                                        strumenti che si concentra su come gli
                                        utenti interagiscono con l'applicazione.
                                        Questo approccio, noto come "testing
                                        user-centric", mira a garantire che i
                                        test riflettano meglio il comportamento
                                        reale dell'applicazione. React Testing
                                        Library fornisce metodi per selezionare
                                        elementi in base a query simili a CSS e
                                        per simulare eventi utente come click e
                                        input.
                                    </p>
                                    <h5>3. Cypress</h5>
                                    <p>
                                        Cypress è un tool potente per
                                        l'esecuzione di test end-to-end. Offre
                                        un'esperienza interattiva in cui è
                                        possibile vedere in tempo reale come
                                        l'applicazione si comporta durante
                                        l'esecuzione dei test. Cypress fornisce
                                        una varietà di comandi per interagire
                                        con l'interfaccia utente, eseguire
                                        asserzioni e visualizzare snapshot degli
                                        step dei test.
                                    </p>
                                    <h5>4. Detox</h5>
                                    <p>
                                        Detox è un framework di testing E2E
                                        specifico per applicazioni React Native.
                                        Offre una vasta gamma di funzionalità,
                                        tra cui la gestione dei gesti
                                        multi-touch e la simulazione di scenari
                                        di navigazione complessi. Detox è in
                                        grado di eseguire test su dispositivi
                                        fisici o emulatori, offrendo un'ampia
                                        copertura per l'ecosistema React Native.
                                    </p>
                                    <p>
                                        Nelle app React e React Native, è
                                        possibile garantire la qualità e
                                        l'affidabilità del software solo tramite
                                        il testing. La valutazione degli
                                        strumenti più idonei dipenderà dalle
                                        caratteristiche del singolo progetto -
                                        per questo affidarsi a dei
                                        professionisti è fondamentale fin
                                        dall’inizio.
                                    </p>
                                    <p>
                                        Hai necessità di un esperto in campo
                                        React per ottimizzare l’esperienza
                                        utente e avere una migliore
                                        manutenibilità? Contattaci e ricevi una
                                        consulenza dai nostri tecnici.
                                    </p>

                                    <br />
                                    <br />
                                    {isMobile ? (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? <br />
                                                    <span
                                                        style={{ fontSize: 20 }}
                                                    >
                                                        Clicca qui
                                                    </span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className="d-flex justify-content-center">
                                                <Link
                                                    to="/l-outsourcing-con-appius"
                                                    className="btn btn-primary"
                                                >
                                                    Hai bisogno di aiuto per il
                                                    tuo progetto? Clicca qui
                                                </Link>
                                            </div>
                                        </>
                                    )}
                                    <br />
                                </div>

                                <div
                                    style={{ marginTop: 30 }}
                                    className="startp-post-navigation"
                                >
                                    {Blog.articles
                                        .filter((a) => a.id != articleData.id)
                                        .slice(0, 2)
                                        .map((article) => {
                                            return (
                                                <div className="prev-link-wrapper">
                                                    <div className="info-prev-link-wrapper">
                                                        <Link
                                                            to={
                                                                "/" +
                                                                article.page
                                                            }
                                                        >
                                                            <span className="image-prev">
                                                                <img
                                                                    src={
                                                                        require("../../assets/images" +
                                                                            article.img)
                                                                            .default
                                                                    }
                                                                    alt="image"
                                                                />
                                                                <span className="post-nav-title">
                                                                    VAI
                                                                </span>
                                                            </span>

                                                            <span className="prev-link-info-wrapper">
                                                                <span className="prev-title">
                                                                    {
                                                                        article.title
                                                                    }
                                                                </span>
                                                                <span className="meta-wrapper">
                                                                    <span className="date-post">
                                                                        {
                                                                            article.data
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </Link>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <BlogSidebar id={articleData.id} />
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Layout>
    );
};

export default BlogDetails;
